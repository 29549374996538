import parsePhoneNumber from 'libphonenumber-js'
import { E164Number } from 'libphonenumber-js/types'

export const useContact = (): {
  phone: { number: string; parsedNumber: E164Number | undefined }
  email: string
} => {
  const phone = {
    number: '(954) 710-8991',
    parsedNumber: parsePhoneNumber('(954) 710-8991', 'US')?.number,
  }

  const email = 'info@credsy.com'

  return {
    phone,
    email,
  }
}
